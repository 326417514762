
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_grant_allocation_distribution.single_pending_distribution') }} </h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="6" md="4" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      :label="$t('dae_grant_allocation_distribution.farmer_card_no')"
                      label-for="farmer_card_no"
                      >
                      <b-form-input
                          plain
                          v-model="search.farmer_card_no"
                          id="farmer_card_no"
                          >
                      </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="6" md="4" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('dae_grant_allocation_distribution.farmer_nid')"
                      label-for="nid_no"
                      >
                        <b-form-input
                          plain
                          v-model="search.nid_no"
                          id="nid_no"
                        >
                      </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="6" md="4" lg="4" xl="4">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('dae_grant_allocation_distribution.single_pending_distribution_list') }}</h4>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loading">
                        <b-row>
                          <b-col md="12" v-if="pendings.length > 0">
                            <div v-for="(listItem, dlIndx) in pendings" :key="'A'+dlIndx" class="mt-1" style="padding:6px">
                              <h5>{{ $t('dae_grant_allocation_distribution.circular') }}: {{ $i18n.locale === 'bn' ? listItem.circular_bn : listItem.circular }} </h5>
                              <div class="table-responsive">
                                <b-table-simple striped bordered small class="mt-2">
                                  <b-thead>
                                    <b-tr>
                                      <b-th :colspan=" listItem.instruments.length + 1" class="text-center">{{ $t('dae_subsidy.instrument_info') }}</b-th>
                                      <b-th :rowspan="2" class="text-center" style="v-align:center">{{ $t('globalTrans.action') }}</b-th>
                                    </b-tr>
                                    <b-tr  v-for="(item,sinPMaterialIndex) in listItem.instruments" :key="'G'+sinPMaterialIndex" >
                                      <b-th class="text-center">
                                        {{ $t('dae_subsidy.instrument_name')}}
                                      </b-th>
                                      <b-th class="text-center">
                                        {{ $t('globalTrans.quantity') }}
                                      </b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(item, sinPMaterialIndex) in listItem.instruments" :key="'F'+sinPMaterialIndex">
                                      <b-td class="text-center">
                                        {{ $i18n.locale === 'bn' ? item.name_bn : item.name }}
                                      </b-td>
                                      <b-td class="text-center">
                                        {{ $n(1) }}
                                      </b-td>
                                      <b-td class="text-center">
                                        <b-button class="mr-1" :title="$t('dae_grant_allocation_distribution.distribute')" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(listItem)"><i class="ri-eye-line m-0 "></i></b-button>
                                        <a href="javascript:" class="btn_table_action table_action_view" :title="$t('globalTrans.print')" @click="printInvoice(listItem)"><i class="fas fa-print"></i></a>
                                      </b-td>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </div>
                            </div>
                          </b-col>
                          <b-col v-else>
                            <p class="text-center">{{ $t('globalTrans.no_data_found') }}</p>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="xl" :title="$t('dae_grant_allocation_distribution.single_pending_distribution') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subSidySingleDistriPendingList } from '../../api/routes'
import ExportPdfInvoice from './invoice'

export default {
  components: {
    Details
  },
  data () {
    return {
      loading: false,
      search: {
        farmer_card_no: '',
        nid_no: ''
      },
      pendings: [],
      item: '',
      authLocation: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        office_id: 0
      }
    }
  },
  // created () {
  //   this.setAuthLocationInfo()
  // },
  computed: {
    listReload () {
      return this.$store.state.commonObj.listReload
    }
  },
  watch: {
    listReload: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
    },
    async loadData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const response = await RestApi.getData(incentiveGrantServiceBaseUrl, subSidySingleDistriPendingList, this.search)
      this.loading = false
      if (response.success) {
        this.pendings = this.getRelationalData(response.data)
      } else {
        this.pendings = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList
      const listData = data.map(item => {
        const circularObject = circularList.find(circularSingle => circularSingle.value === item.circular_id)
        const farmerObject = item.farmer

        const circularNameData = {
          circular: typeof circularObject !== 'undefined' ? circularObject.text_en : '',
          circular_bn: typeof circularObject !== 'undefined' ? circularObject.text_bn : ''
        }

        const farmerData = {
          name_en: farmerObject !== undefined ? farmerObject.name : '',
          name_bn: farmerObject !== undefined ? farmerObject.name_bn : '',
          card_no: farmerObject !== undefined ? farmerObject.other_info.card_number : '',
          nid_no: farmerObject !== undefined ? farmerObject.nid_no : ''
        }
        return Object.assign({}, item, circularNameData, farmerData)
      })
      return listData
    },
    setAuthLocationInfo () {
      let location = {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        office_id: 0
      }
      const authUser = this.$store.state.Auth.authUser
      if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
        if (typeof office !== 'undefined') {
          location = {
            division_id: office.division_id,
            district_id: office.district_id,
            upazilla_id: office.upazilla_id,
            office_id: office.value
          }
        }
      }
      this.search = Object.assign({}, this.search, location)
      this.authLocation = Object.assign({}, this.authLocation, location)
    },
    printInvoice (item) {
      ExportPdfInvoice.exportPdfInvoiceDetails(this, item)
    }
  }
}
</script>
