import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfInvoiceDetails = async (vm, itemList) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }

    const farmerName = (i18n.locale === 'en') ? itemList.name_en : itemList.name_bn

    const pdfContent = [
      { text: vm.$t('dae_grant_allocation_distribution.farmer_name') + ' : ' + farmerName, style: 'td', alignment: 'left' },
      { text: vm.$t('dae_grant_allocation_distribution.farmer_nid') + ' : ' + vm.$n(itemList.nid_no, { useGrouping: false }), style: 'td', alignment: 'left' },
      { text: vm.$t('dae_grant_allocation_distribution.farmer_card_no') + ' : ' + vm.$n(itemList.card_no, { useGrouping: false }), style: 'td', alignment: 'left' }
    ]

    pdfContent.push({ text: '', style: 'space' })
    pdfContent.push({ text: vm.$t('dae_subsidy.instrument_info'), style: 'thh' })

    const allRows = []

    allRows.push([
      { text: vm.$t('dae_subsidy.instrument_name'), alignment: 'center', style: 'th' },
      { text: vm.$t('globalTrans.quantity'), alignment: 'center', style: 'th' }
    ])

    itemList.instruments.forEach((instr, index) => {
      allRows.push([
        { text: i18n.locale === 'en' ? instr.name : instr.name_bn, alignment: 'center', style: 'th' },
        { text: vm.$n(1), alignment: 'center', style: 'th' }
      ])
    })

    pdfContent.push({
      table: {
        headerRows: 1,
        widths: '*',
        body: allRows
      }
    })

    var docDefinition = {
      content: pdfContent,
      // pageSize: 'A4',
      pageSize: {
        width: 200,
        height: 'auto'
      },
      pageOrientation: 'portrait',
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 9 : 8,
          margin: [3, 3, 3, 3]
        },
        thh: {
          fontSize: (i18n.locale === 'bn') ? 12 : 10,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 9 : 8,
          margin: [3, 3, 3, 3]
        },
        tdCustom: {
          fontSize: (i18n.locale === 'bn') ? 9 : 8,
          padding: [0, 0, 0, 10]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 11,
          margin: [3, 3, 3, 3]
        },
        space: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 17,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfInvoiceDetails
}
