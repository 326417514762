<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_subsidy.distribution') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <iq-card>
                                                <template v-slot:body>
                                                    <template>
                                                        <div style="font-size:12px; background-color: #337982;">
                                                            <h5 class="text-white text-center"> {{ $t('dae_grant_allocation_distribution.distribute_quantity') }}</h5>
                                                        </div>
                                                    </template>
                                                    <b-table-simple striped bordered small class="mt-2 text-center">
                                                        <b-tr>
                                                            <b-th :colspan="instruments.length" class="text-center">{{ $t('dae_subsidy.instrument_name') }}</b-th>
                                                            <b-th class="text-center">{{ $t('globalTrans.quantity') }}</b-th>
                                                        </b-tr>
                                                        <b-tr v-for="(item, sinPCropIndex) in instruments" :key="'E'+sinPCropIndex">
                                                            <b-td>
                                                                 {{ ($i18n.locale === 'bn') ? item.name_bn : item.name }}
                                                            </b-td>
                                                            <b-td  class="text-center">
                                                                {{ $n(1) }}
                                                            </b-td>
                                                        </b-tr>
                                                    </b-table-simple>
                                                </template>
                                            </iq-card>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <iq-card>
                                                <template v-slot:body>
                                                    <template>
                                                        <div style="font-size:12px; background-color: #337982;">
                                                            <h5 class="text-white text-center"> {{ $t('dae_subsidy.stock_list') }}</h5>
                                                        </div>
                                                    </template>
                                                    <b-table-simple striped bordered small class="mt-2">
                                                        <b-tr>
                                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.items_name') }}</b-th>
                                                            <b-th width="100px" class="text-center">{{ $t('dae_subsidy.quantity') }}</b-th>
                                                        </b-tr>
                                                        <b-tr v-for="(stock,index) in stocks" :key="index" class="text-center">
                                                            <b-td>{{ $n(index+1) }}</b-td>
                                                            <b-td>{{ itemName(stock.item_id) }}</b-td>
                                                            <b-td><input class="form-control" type="text" v-model="stock.quantity" readonly/></b-td>
                                                        </b-tr>
                                                    </b-table-simple>
                                                </template>
                                            </iq-card>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Date" vid="distribution_date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="distribution_date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('dae_subsidy.distribution_data') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    class="fromDate"
                                                    id="distribution_date"
                                                    v-model="formData.distribution_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :placeholder="$t('dae_subsidy.select_date')"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Place (En)" vid="place" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="place"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_subsidy.place_en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="place"
                                                        v-model="formData.place"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Place (Bn)" vid="place_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="place_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_subsidy.place_bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="place"
                                                        v-model="formData.place_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9"></div>
                                        <div class="col-sm-6 col-md-3">
                                            <div class="text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subSidySingleDistribute, subSidySingleDistriDetails } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
      this.instruments = this.item.instruments
    },
    data () {
        return {
            loading: false,
            instruments: [],
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('dae_subsidy.distributed'),
            formData: {
                place: '',
                place_bn: '',
                distribution_date: ''
            },
            stocks: [],
            validationResult: {
                success: true,
                message: ''
            },
            authLocation: {
              division_id: 0,
              district_id: 0,
              upazilla_id: 0
            }
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
        this.singleDistPendingDetails()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        async singleDistPendingDetails () {
            this.loading = true
            const params = {
                circular_id: this.item.circular_id,
                fiscal_year_id: this.item.fiscal_year_id,
                instrument_ids: this.getId(this.item.instruments),
                far_general_info_id: this.item.far_gen_info_id
            }
            const response = await RestApi.getData(incentiveGrantServiceBaseUrl, subSidySingleDistriDetails, params)
            this.loading = false
            if (response.success) {
                this.stocks = response.stocks
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: response.message,
                    color: '#fff'
                })
                this.stocks = []
            }
        },
        getId (data) {
            return data.map(item => {
                return parseInt(item.id)
            })
        },
        async saveData () {
            this.validationCheck()
            if (!this.validationResult.success) {
                this.displayError(this.validationResult.message)
                return
            }
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            this.formData = Object.assign({}, this.formData, {
                far_general_info_id: this.item.far_gen_info_id,
                fiscal_year_id: this.item.fiscal_year_id,
                circular_id: this.item.circular_id,
                instruments: this.item.instruments
            })
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, subSidySingleDistribute, this.formData)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-5')
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: result.message,
                    color: '#fff'
                })
            }
        },
        validationCheck () {
            this.item.instruments.map(el => {
                const tmpInstrument = this.stocks.find(item => item.item_id === el.id && el.quantity >= item.quantity)
                if (typeof tmpInstrument !== 'undefined') {
                    this.validationResult.success = false
                    this.validationResult.message = this.$t('dae_grant_allocation_distribution.quantityExist')
                }
            })
        },
        displayError (message) {
            this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: message,
                color: '#fff'
            })
        },
        getCropName (cropId) {
            const objectData = this.$store.state.incentiveGrant.commonObj.cropList.find(item => item.value === cropId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
        itemName (items) {
            const objectData = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === items)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
        getUnitType (unit) {
            return unit === 1 ? this.$t('dae_grant_allocation_distribution.kg') : ''
        },
        setAuthLocationInfo () {
          let location = {
            division_id: 0,
            district_id: 0,
            upazilla_id: 0
          }
          const authUser = this.$store.state.Auth.authUser
          if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
            const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
            if (typeof office !== 'undefined') {
              location = {
                division_id: office.division_id,
                district_id: office.district_id,
                upazilla_id: office.upazilla_id
              }
            }
          }
          this.formData = Object.assign({}, this.formData, location)
          this.authLocation = Object.assign({}, this.authLocation, location)
        }
    }
}
</script>
